import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import { useEffect } from "react";


const TagsIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
}

export default TagsIndex

export const pageQuery = graphql`
  query tagsArchive($offset: Int!, $postsPerPage: Int!) {
    allWpTag(
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        name
        posts {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
                date
              }
            }
          }
        }
      }
    }
  }
`
